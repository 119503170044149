import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../layouts/main"
import Results from "../../../components/Configurator/Results"
import { makeStyles, Grid }  from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    minHeight: "100vh",
    overflowX: "hidden",
    padding: theme.spacing(1)
  },
  gridItem: {
    overflow: "hidden",
    paddingBottom: theme.spacing(4),
  }
}))

const ConfiguratorPage = ({location}) => {
  const classes = useStyles()

  return (
  <Layout location={location} secondaryBackgroundColor>
    <SEO title="Configurator" />
    <Grid 
      className={classes.gridWrapper}
      container  
      justify="center"
      alignItems="flex-start"
    >
        <Grid item md={8} className={classes.gridItem}>
            <Results/>
        </Grid>    
    </Grid>    
  </Layout>
)}

export default ConfiguratorPage